<script setup>
import Logo from '@/components/layout/Logo.vue'
import SocialLinks from '@/components/layout/SocialLinks.vue'
import FooterCopy from '@/components/layout/FooterCopy.vue'
import FooterMenu from '@/components/layout/FooterMenu.vue'
import CartIcon from '@/components/icons/CartIcon.vue'
import TextButton from '@/components/shared/buttons/TextButton.vue'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton.vue'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton.vue'
import { useDisplay } from 'vuetify'
import { onMounted } from 'vue'

const { mobile } = useDisplay()
const drawer = ref(false)

onMounted(() => {
})
</script>

<template>
  <v-app>
    <ClientOnly>
      <v-app-bar flat rounded="b-xl" color="white" :border="true" class="header">
        <template v-slot:prepend>
          <NuxtLink to="/" class="d-block">
            <Logo />
          </NuxtLink>
        </template>

        <template v-slot:append>
          <template v-if="!mobile">
            <TextButton class="mx-3">Start hosting</TextButton>
            <SecondaryButton class="mx-3">Sign in</SecondaryButton>
            <PrimaryButton class="mx-3">Sign up</PrimaryButton>

            <v-divider length="44" vertical class="h-100 align-self-center mx-5"></v-divider>

            <div>
              <CartIcon />
            </div>
          </template>

          <div v-if="mobile">
            <button @click="drawer = !drawer">
              <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
                  fill="#3C9273" />
              </svg>
            </button>
          </div>
        </template>
      </v-app-bar>

    </ClientOnly>
    <v-main class="main">
      <slot></slot>
    </v-main>

    <v-footer color="white" class="footer">
      <v-container fluid class="pa-3">
        <!-- <v-divider class="mb-12"></v-divider> -->

        <v-row class="mb-4">
          <v-col cols="12" md="4">
            <div class="logo-wrapper">
              <Logo />
            </div>
            <SocialLinks />
          </v-col>

          <v-col cols="12" md="8">
            <FooterMenu />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <FooterCopy />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <ClientOnly>
      <v-navigation-drawer v-model="drawer" temporary location="right">
        <div class="mobile-menu-wrapper">
          <div class="mobile-menu">
            <div class="mobile-menu__item">
              <NuxtLink to="#">Start hosting</NuxtLink>
            </div>
            <div class="mobile-menu__item">
              <NuxtLink to="#">Sing in</NuxtLink>
            </div>
            <div class="mobile-menu__item">
              <NuxtLink to="#">Sign up</NuxtLink>
            </div>
          </div>
        </div>
      </v-navigation-drawer>
    </ClientOnly>
  </v-app>
</template>

<style lang="scss" scoped>
.header {
  padding: 19px 90px !important;
}

.main {
  padding: 105px 100px 0;
  overflow: hidden;
}

.footer {
  padding: 48px 88px 0;
  flex: 0 0 auto;
  background-image: url('@/assets/img/footer-bg.jpg');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.logo-wrapper {
  margin-bottom: 40px;
}

.v-navigation-drawer {
  &::before {
    display: block;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 64px;
    background: white;
  }
}

.mobile-menu-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-menu {
  padding-top: 20px;
}

.mobile-menu__item {
  font-size: 18px;
  padding: 12px 20px;

  a {
    text-decoration: none;
    color: #3c464d;
  }
}

@media screen and (max-width: 1439px) {
  .header {
    padding: 19px 40px !important;
  }

  .main {
    padding: 105px 40px 0;
  }

  .footer {
    padding: 48px 40px 0;
  }
}

@media screen and (max-width: 1239px) {
  .header {
    padding: 19px 20px !important;
  }

  .main {
    padding: 105px 20px 0;
  }

  .footer {
    padding: 48px 20px 0;
  }
}

@media screen and (max-width: 899px) {
  .header {
    padding: 0 16px !important;
  }

  .main {
    padding: 64px 16px 0;
  }

  .footer {
    padding: 24px 16px;
  }

  .logo-wrapper {
    margin-bottom: 20px;
  }
}
</style>
